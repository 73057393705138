import React from "react";
const DashCard = (props) => {
  return (
    <div className="dash-card" onClick={props?.onClick}>
      <div className="dash-icon">
        <img src={props.icon} alt= ""/>
      </div>
      <div >
        <h3 className="dash-title">{props.title}</h3>
        <p className="dash-card-subtitle" >{props.subTitle}</p>
      </div>
    </div>
  );
};
export default DashCard;
