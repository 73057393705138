import React from "react";
import { AppBackgrounds } from "../assets/background/background";

const JobCardSeeker = (props) => {
  const { job, onClick, hideImage } = props;
  

  return (
    <div className="job-card my-2" onClick={onClick}>
       {/* Conditionally render the image container */}
       {!hideImage && (
    <div className="job-img" style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
  <img
    src={`https://firebasestorage.googleapis.com/v0/b/kontha-partners
.appspot.com/o/jobs%2F${job.id}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`}
    alt=''
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',  // Ensures the image covers the container
      objectPosition: 'center',  // Centers the image
    }}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = AppBackgrounds.bg_1;
    }}
  />
</div>
   )}
      <div className="job-card-body">
        <p className="mb-2 text-muted" style={{ fontSize: "12px" }}>

          {job.recruiterName}

        </p>
        <div style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "8px" }}>
  
  {/* <div style={{ backgroundColor: "#f9f9f9", padding: "15px", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}> */}
  <h4 style={{ marginBottom: "10px" }}>{job.title}</h4>
  <div style={{ color: "gray" }}>
    {/* <p style={{ margin: "0", fontSize: "16px" }}>{job.classification}</p>
    <p style={{ margin: "0", fontSize: "16px" }}>{job.city}, {job.state}</p>
    <p style={{ margin: "0", fontSize: "16px" }}>{job.setting}</p> */}
    <p className="text-muted">
          {/* {job.populationServed?.join(", ")} */}
        </p>
  </div>
{/* </div> */}

</div>

        
        {/* <p className="d-flex justify-content-between">
          {job.slpStudents ? (
            <span>Case Load : {job.slpStudents}</span>
          ) : (
            <span>{job.setting}</span>
          )}
          <span>Platform: {job.telepathyPlatform}</span>
        </p> */}
        <p className="d-flex justify-content-between">
          <span>Applicants : {Object.values(job?.applicants)?.length}</span>
          <span
            className={
              job.status === "active" ? "jb bg-success text-white": "jb bg-danger text-white"

            
            }
          >
            {job.status}
          </span>
        </p>
      
      </div>
    </div>
  );
};

// Exporting the memoized component
export default React.memo(JobCardSeeker);
