import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {  Col, Row, Pagination, Container } from "react-bootstrap";
import { AltJobService } from "../services/AltJobs";
import { JobService } from "../services/Jobs";
// import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import firebase from "firebase/compat/app"; // Import Firebase
import "firebase/compat/firestore"; // Firestore
import "./JobsDashboard.css";
import {
  Button,
  Modal
  
} from "@mui/material";

// Spinner Component
const Spinner = () => (
  <div className="d-flex justify-content-center my-3">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);


// ErrorMessage Component
// const ErrorMessage = ({ message }) => (
//   <div className="alert alert-danger" role="alert">
//     {message}
//   </div>
// );

// ErrorMessage Component
const ErrorMessage = ({ message }) => (
  <div className="no-jobs-message">
    <p>{message}</p>
  </div>
);


// Reusable JobCard component
 export const JobCard = ({ job, onClick }) => (
  <Col lg={4} xs={12} onClick={onClick} role="button" tabIndex={0} onKeyPress={onClick}>
    <div className="job-card my-2" style={{ minHeight: "300px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <div className="job-card-body">
      <h6 style={{ fontSize: "12px", margin: "0", paddingBottom: "2px" }}>Date: 
  {new Date(job.creationTime).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric"
  })}
</h6>
<h6 style={{ fontSize: "12px", margin: "0" }}>Time: 
  {new Date(job.creationTime).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
  })}
</h6>


        <h4 style={{marginTop: "5px", marginBottom: "15px"  }}>{job.title}</h4>
        <h6><strong>Description</strong> {job.description}</h6>  
        <h6><strong>RSI:</strong> </h6>         
        <h6><strong>Profit/Loss:</strong> </h6>  
        <p className="d-flex justify-content-between">
          {/* <span><strong>Applicants:</strong> {job.applicants ? Object.values(job.applicants).length : 0}</span>  */}
          <span className={job.status === "active" ? "jb bg-success text-white": "jb bg-danger text-white" }>
          {/* <span className={job.status === "active" ? "jb bg-warning" : "jb bg-success"}> */}
            {job.status}
          </span>
          </p>
      </div>
    </div>
  </Col>
);


// SkeletonLoader component to display while loading
// const SkeletonJobCard = () => (
//   <Col lg={4} xs={12}>
//     <div className="job-card-skeleton my-2">
//       <div className="job-img-skeleton" />
//       <div className="job-card-body-skeleton">
//         <div className="skeleton-title" />
//         <div className="skeleton-line" />
//         <div className="skeleton-line" />
//       </div>
//     </div>
//   </Col>
// );

// Reusable PaginationComponent
const PaginationComponent = ({ page, totalPages, onPageChange, loading }) => {
  if (totalPages < 1) return null; // Ensure valid pagination

  return (
    <div style = {{paddingTop:"20px"}}className="d-flex justify-content-center align-items-center">
      <Pagination className="my-2 align-items-center">
        <Pagination.First onClick={() => onPageChange(1)} disabled={page === 1 || loading} />
        <Pagination.Prev onClick={() => onPageChange(page - 1)} disabled={page === 1 || loading} />
        {[...Array(totalPages)].map((_, i) => (
          <Pagination.Item
            key={i + 1}
            active={i + 1 === page}
            onClick={() => onPageChange(i + 1)}
            disabled={loading}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => onPageChange(page + 1)}
          disabled={page === totalPages || loading || totalPages === 0}
        />
        <Pagination.Last
          onClick={() => onPageChange(totalPages)}
          disabled={page === totalPages || loading || totalPages === 0}
        />
      </Pagination>
    </div>
  );
};

// JobDashboard component
const JobDashboard = () => {
  // const [displayName, setDisplayName] = useState(null); // State to store the displayName
  // const [loadingAuth, setLoadingAuth] = useState(true); // Loading state for auth

  const [open, setOpen] = useState(false);
  const [companyName, setCompanyName] = useState(null); // State to store the company name
  const [loadingCompany, setLoadingCompany] = useState(true); // Loading state for company
// const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.authReducer.auth);
  const isCompany = useSelector((state) => state.authReducer.isCompany);

  const [jobs, setJobs] = useState([]);
  const [altJobs, setAltJobs] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingAltJobs, setLoadingAltJobs] = useState(false);
  const [jobError, setJobError] = useState(null); // State for job errors
  const [altJobError, setAltJobError] = useState(null); // State for alt job errors

  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    firstVisible: null,
    lastVisible: null,
  });
  const [altPagination, setAltPagination] = useState({
    page: 1,
    totalPages: 1,
    firstVisible: null,
    lastVisible: null,
  });

  const itemsPerPage = 6; // Number of items per page

  // Fetch company name from Firestore


/* eslint-disable react-hooks/exhaustive-deps */
// useEffect(() => {
//   if (!isCompany) {
//     navigate("/manage-trades/your-applications");
//   } else {
//     fetchJobs(user.uid, pagination.page);
//     fetchAltJobs(user.uid, altPagination.page);
//   }
// }, []);
/* eslint-enable react-hooks/exhaustive-deps */



  // Memoized fetchJobs function
  const fetchJobs = useCallback(async (userId, page, prev = false) => {
    try {
      console.log("Fetching jobs for user:", userId, "Page:", page);
      setLoadingJobs(true);
      setJobError(null); // Reset error state before fetching
  
      let res;
      if (page === 1) {
        res = await JobService.getJobs(userId, itemsPerPage, null);
      } else if (prev && page > 1) {
        res = await JobService.getJobs(userId, itemsPerPage, pagination.firstVisible, true);
      } else {
        res = await JobService.getJobs(userId, itemsPerPage, pagination.lastVisible);
      }
  
      console.log("Fetched jobs response:", res); // Log the response from Firestore
  
      if (res.docs.length > 0) {
        const firstVisible = res.docs[0];
        const lastVisible = res.docs[res.docs.length - 1];
        const jobPromises = res.docs.map(async (i) => {
          const jobDetails = i.data();
          const applicants = await i.ref.collection("applicants").get();
          console.log(applicants, "regular job applicants jdash"); // Log applicants data for each job
          return {
            ...jobDetails,
            id: i.id,
            applicants: applicants.docs.map((x) => x.data()),
          };
        });
  
        const jobAry = await Promise.all(jobPromises);
        const totalJobs = await JobService.getTotalJobs(userId);
        const newTotalPages = Math.ceil(totalJobs / itemsPerPage);
  
        console.log("Jobs array after processing:", jobAry); // Log the processed job data
  
        setJobs(jobAry);
        setPagination((prev) => ({
          ...prev,
          page,
          totalPages: newTotalPages,
          firstVisible,
          lastVisible,
        }));
      } else {
        console.log("No jobs found."); // Log if no jobs were found
        setJobError("No jobs found.");
      }
    } catch (error) {
      console.log("Error fetching jobs:", error); // Log the error if something goes wrong
      setJobError("Unable to fetch trades. Please try again later.");
    } finally {
      setLoadingJobs(false); // Ensure loading is stopped in all cases
    }
  }, [itemsPerPage, pagination.firstVisible, pagination.lastVisible]);
  
  // Memoized fetchAltJobs function
  const fetchAltJobs = useCallback(async (userId, page, prev = false) => {
    try {
      setLoadingAltJobs(true);
      setAltJobError(null); // Reset error state before fetching
      let res;

      if (page === 1) {
        res = await AltJobService.getAllJobs(userId, itemsPerPage, null);
      } else if (prev && page > 1) {
        res = await AltJobService.getAllJobs(userId, itemsPerPage, altPagination.firstVisible, true);
      } else {
        res = await AltJobService.getAllJobs(userId, itemsPerPage, altPagination.lastVisible);
      }

      if (res.docs.length > 0) {
        const firstVisible = res.docs[0];
        const lastVisible = res.docs[res.docs.length - 1];
        const jobPromises = res.docs.map(async (i) => {
          const jobDetails = i.data();
          const applicants = await i.ref.collection("applicants").get();
          return {
            ...jobDetails,
            id: i.id,
            applicants: applicants.docs.map((x) => x.data()),
          };
        });

        const jobAry = await Promise.all(jobPromises);
        const totalAltJobs = await AltJobService.getTotalAltJobs(userId);
        const newTotalPages = Math.ceil(totalAltJobs / itemsPerPage);

        setAltJobs(jobAry);
        setAltPagination((prev) => ({
          ...prev,
          page,
          totalPages: newTotalPages,
          firstVisible,
          lastVisible,
        }));
      } else {
        setAltJobError("No alternate jobs found.");
      }
    } catch (error) {
      setAltJobError("Unable to fetch trades. Please try again later.");
    } finally {
      setLoadingAltJobs(false);
    }
  }, [itemsPerPage, altPagination.firstVisible, altPagination.lastVisible]);


  /* eslint-disable-next-line react-hooks/exhaustive-deps */



  const handlePageChange = (newPage) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (newPage >= 1 && newPage <= pagination.totalPages) {
      setPagination((prev) => ({ ...prev, page: newPage }));
      fetchJobs(user.uid, newPage, newPage < pagination.page);
    }
  };

  const handleAltPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= altPagination.totalPages) {
      setAltPagination((prev) => ({ ...prev, page: newPage }));
      fetchAltJobs(user.uid, newPage, newPage < altPagination.page);
    }
  };


  const fetchCompanyName = useCallback(async (companyId) => {
    try {
      const companyRef = firebase.firestore().collection("companies").doc(companyId);
      const companyDoc = await companyRef.get();
      if (companyDoc.exists) {
        setCompanyName(companyDoc.data().name); // Set the company name
      } else {
        console.error("No such company!");
      }
    } catch (error) {
      console.error("Error fetching company name:", error);
    } finally {
      setLoadingCompany(false); // Stop loading after fetching
    }
  }, []);
  
 // eslint-disable-next-line
 useEffect(() => {
  if (user && isCompany) {
    fetchCompanyName(user.uid); // Fetch company name
    fetchJobs(user.uid, 1); // Fetch jobs on initial load
    fetchAltJobs(user.uid, 1); // Fetch alternate jobs on initial load
  }
}, [user, isCompany, fetchCompanyName, fetchJobs, fetchAltJobs]); // Add functions as dependencies




  return (
    <>
      <Container fluid className="mb-5">
      <Row>
  <Col xs={6}>
    {loadingCompany ? (
      <Spinner />
    ) : companyName ? (
      <h3 className="company-title">{companyName} Bot Trades</h3>
    ) : (
      <h3 className="fallback-title">Bot Trades</h3>
    )}
  </Col>

  {/* Separate buttons into their own Row */}
  <Col xs={6} className="d-flex justify-content-end align-items-center">
    {/* <Button onClick={handleOpen} variant="outlined" size="small" className="me-2">
      Applicants Info
    </Button> */}

    {isCompany && (
      <>
        <Button
          onClick={() => navigate("/manage-trades/create")}
          variant="outlined"
          size="small"
          className="me-2"
        >
          Start Bot
        </Button>

        <Button
          onClick={() => navigate("/manage-trades/create-alt")}
          variant="outlined"
          size="small"
        >
          Create Own Trade
        </Button>
      </>
    )}
  </Col>
</Row>

{/* Loading spinner */}
{loadingJobs && (
  <Row>
    <Col xs={12} className="d-flex justify-content-center">
      <Spinner />
    </Col>
  </Row>
)}

{/* Modal Definition */}
<Modal open={open} onClose={handleClose}>
  <div style={{ padding: '20px', background: '#fff', margin: '100px auto', maxWidth: '400px' }}>
    <h3>Applicants Guidelines</h3>
    <p>Applicants will be found under each of your jobs posted in this section.</p>
    <p>Click on the Job Card and the applicants will be on the details page.</p>
    <p>Please close jobs when finished.</p>
    <Button onClick={handleClose} variant="contained">
      Close
    </Button>
  </div>
</Modal>



<Row className="data-container">
  {loadingJobs ? (
    <Spinner />
  ) : jobError ? (
    <ErrorMessage message={jobError} />
  ) : jobs.length === 0 ? (
    <ErrorMessage message="No Trades available at the moment." />
  ) : (
    jobs.map((job) => (
      <JobCard
        key={job.id}
        job={job}
        onClick={() => navigate("/manage-trades/status/" + job.id)}
      />
    ))
  )}
</Row>

        {jobs.length > 0 && (
          <PaginationComponent
            page={pagination.page}
            totalPages={pagination.totalPages}
            onPageChange={handlePageChange}
            loading={loadingJobs}
          />
        )}
      </Container>

      <Container fluid>
        <Row>
          {/* <h3>{displayName}'s Alt Jobs</h3> */}
          {loadingCompany ? (
  <Spinner />
) : companyName ? (
  <Col xs={6}>
    <h3 className="company-title">
      {companyName} Created Trades
    </h3>
  </Col>
) : (
  <Col xs={6}>
    <h3 className="fallback-title">
     Created Trades
    </h3>
  </Col>
)}


        </Row>
        <Row className="data-container">
          {loadingAltJobs ? (
            <Spinner />
          ) : altJobError ? (
            <ErrorMessage message={altJobError} />
          ) : (
            altJobs.map((job) => (
              <JobCard
                key={job.id}
                job={job}
                onClick={() => navigate("/manage-trades/status-alt/" + job.id)}
              />
            ))
          )}
        </Row>
        {altJobs.length > 0 && (
          <PaginationComponent
            page={altPagination.page}
            totalPages={altPagination.totalPages}
            onPageChange={handleAltPageChange}
            loading={loadingAltJobs}
          />
        )}
      </Container>
    </>
  );
};

export default JobDashboard;