import { db, storage } from "../firebase-config";
import avatarHolder from '../assets/avatarHolder.png';

export class ProfileService {
  static createProfile = (id, name, email, avatar) => {
    const docRef = db.collection("profile").doc(id);
  
    // Only include the avatar field if it's defined
    const profileData = {
      id: id,
      name: name,
      email: email,
      avatar: avatar || null,  // Provide null if avatar is undefined
    };
  
    return docRef.set(profileData);
  };
  
  static getProfileDetails = async (id) => {
    const docRef = db.collection("profile").doc(id);
    return docRef.get();
    
  };
  static updateProfileDetails = async (id, data) => {
    const docRef = db.collection("profile").doc(id);
  
    const cleanedData = {};
    // Iterate over the data to ensure it's stored as plain strings
    Object.keys(data).forEach((key) => {
      cleanedData[key] = typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
    });
  
    return docRef.update(cleanedData);
  };

  static getProfileAvatarUrl = async (userId) => {
    try {
      const storageRef = storage.ref();
      const avatarRef = storageRef.child(`profile/avatar/${userId}`);
      const avatarUrl = await avatarRef.getDownloadURL();
      return avatarUrl; // Return avatar URL if it exists
    } catch (error) {
      // If the avatar doesn't exist, fallback to a default avatar
      if (error.code === "storage/object-not-found") {
        console.info("Avatar not found, using default avatar");
        return avatarHolder; // Return default avatar if not found
      }
      // Log and re-throw other types of errors
      console.error("Error fetching avatar from storage:", error.message);
      throw error;
    }
  };
  

  static uploadResume = async (file, userId) => {
    const storageRef = storage.ref(); // Assuming you have configured Firebase storage

    // Create a reference to 'resumes/{userId}/{filename}'
    const fileRef = storageRef.child(`resumes/${userId}/${file.name}`);

    // Upload the file to Firebase Storage
    const snapshot = await fileRef.put(file);

    // Get the URL of the uploaded file
    const downloadURL = await snapshot.ref.getDownloadURL();

    return downloadURL; // Return the file URL
  };
  
  static getProfiles = (page = 1, limit = 10) => {
    const docRef = db.collection("profile");
    return docRef.get();
  };
  static getProfilesName = (name) => {
    const docRef = db.collection("profile").where("name", "<=", name);
    return docRef.get();
  };
  static storeProfileImage = async (image, id) => {
    // Store the image in Firebase Storage
    const storageRef = storage.ref(`/profile/avatar/${id}`);
    await storageRef.putString(image, "base64", { contentType: "image/jpg" });
  
    // Return the download URL of the newly stored image
    const downloadURL = await storageRef.getDownloadURL();
    return downloadURL;
  };
  


}