import { db, storage } from "../firebase-config";

export class CompanyService {
  static createCompany = (id, name, email, avatar) => {
    const docRef = db.collection("companies").doc(id);
    return docRef.set({
      id: id,
      name: name,
     
      // phone: phone,
      avatar: avatar || null,  // Provide null if avatar is undefined
    });
  };
  static getCompanyDetails = async (id) => {
    const docRef = db.collection("companies").doc(id);
    return docRef.get();
  };
  static updateCompanyDetails = async (id, data) => {
    const docRef = db.collection("companies").doc(id);
    return docRef.update({
      id: id,
      name: data.name,
      username: data.username,
      
      // phone: data.phone,
      bio: data.bio,
      
      fb: data.fb,
      insta: data.insta,
      blog: data.blog,
      yt: data.yt,
      twitter: data.twitter,
      linkedin: data.linkedin,
      
    });
  };
  static getProfiles = (page = 1, limit = 10) => {
    const docRef = db.collection("profile");
    return docRef.get();
  };
  static storeCompanyImage = (image, id, type) => {
    return storage
      .ref(`/company/${type}`)
      .child(id)
      .putString(image, "base64", { contentType: "image/jpg" });
  };
  static getCoompanyName = (name) => {
    const docRef = db.collection("companies").where("name", "==", name);
    return docRef.get();
  };
}
