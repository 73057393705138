import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CreateAltJob from "../components/forms/CreateAltJob";
const CreateAltJobPage = () => {
  const isCompany = useSelector((state) => state.authReducer.isCompany);
  const navigate = useNavigate(); // Corrected typo

  useEffect(() => {
    if (!isCompany) navigate("/jobs");
  }, [isCompany, navigate]); // Added dependencies

  return <CreateAltJob />;
};

export default CreateAltJobPage;
