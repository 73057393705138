import React, { useEffect } from "react";
import CreateJob from "../components/forms/CreateJob";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
const CreateJobPage = () => {
  const isCompany = useSelector((state) => state.authReducer.isCompany);
  const navigate = useNavigate(); // Corrected typo

  useEffect(() => {
    if (!isCompany) navigate("/jobs");
  }, [isCompany, navigate]); // Added dependencies

  return <CreateJob />;
};
export default CreateJobPage;
