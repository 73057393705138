import React, { useEffect, useState, useCallback} from "react";
import ProfileCard from "../components/ProfileCard";
import { ProfileService } from "../services/Profile";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Spinner } from 'react-bootstrap';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import statesAndCities from '../services/statesAndCities.json';  // Import your states and cities JSON

const FilterProfiles = () => {
  const [profiles, setProfiles] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const classifications = [
    "SLP",
    "SLPA",
    "SLP - CF",
    "Virtual Assistant",
    "Paraprofessional",
  ];

  const [appFilters, setAppFilters] = useState({
    name: "",
    role: "",
    state: "",
    city: "",
  });

  // Update city options based on selected state
  useEffect(() => {
    if (selectedState) {
      setCityOptions(statesAndCities[selectedState] || []);
      setAppFilters((prevFilters) => ({ ...prevFilters, city: "" }));
    }
  }, [selectedState]);

  // Fetch and filter profiles
  const getProfiles = useCallback(async () => {
    setLoading(true);
    try {
      const res = await ProfileService.getProfiles();
      if (!res.empty) {
        let result = res.docs.map((i) => i.data());

        // Filter profiles based on the active status and selected filters
        result = result.filter((i) => {
          const { name, role, state, city } = appFilters;
          return (
            i.active === "YES" &&  // Only render active profiles
            (!name || i.name?.toLowerCase().includes(name.toLowerCase())) &&
            (!role || i.role?.toLowerCase() === role.toLowerCase()) &&
            (!state || i.state === state) &&  // Match the selected state
            (!city || i.city === city)  // Match the selected city
          );
        });

        setProfiles(result);  // Set filtered profiles
      } else {
        setProfiles([]);  // Clear profiles if no matches
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }, [appFilters]);

  // Handle search and filter application
  const dosearch = () => {
    getProfiles();
  };

  // Handle clearing the filters
  const clear = () => {
    setAppFilters({
      name: "",
      role: "",
      state: "",
      city: ""
    });
    setSelectedState('');
    setSelectedCity('');
    setCityOptions([]);
    setProfiles([]); // Clear profiles when filters are cleared
  };
  

  return (
    <Container>
      <Row>
        <div><h6>Only profiles actively seeking a job will be displayed.</h6></div>
        {/* Search by name */}
        <Col md={3}>
          <TextField
            fullWidth
            label="Search Profiles..."
            name="name"
            variant="outlined"
            value={appFilters.name}
            onChange={(e) => setAppFilters({ ...appFilters, name: e.target.value })}
            type="text"
          />
        </Col>

        {/* Filter by role */}
        <Col md={3}>
          <FormControl fullWidth>
            <InputLabel>Job Role</InputLabel>
            <Select
              value={appFilters?.role}
              label="Job Role"
              onChange={(e) => setAppFilters({ ...appFilters, role: e.target.value })}
            >
              {classifications.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>

        {/* Filter by state */}
        <Col md={3}>
          <FormControl fullWidth>
            <InputLabel>State</InputLabel>
            <Select
              value={selectedState}
              onChange={(e) => {
                setSelectedState(e.target.value);
                setAppFilters({ ...appFilters, state: e.target.value });
              }}
              label="State"
            >
              <MenuItem value="">-- Select --</MenuItem>
              {Object.keys(statesAndCities).map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>

        {/* Filter by city */}
        <Col md={3}>
          <FormControl fullWidth>
            <InputLabel>City</InputLabel>
            <Select
              value={selectedCity}
              onChange={(e) => {
                setSelectedCity(e.target.value);
                setAppFilters({ ...appFilters, city: e.target.value });
              }}
              label="City"
              disabled={!selectedState}
            >
              <MenuItem value="">-- Select --</MenuItem>
              {cityOptions.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>

        {/* Search and Clear buttons */}
        <Col md={3} className="mt-2">
          <Button onClick={dosearch}>Search</Button>
          {(appFilters.name || appFilters.role || appFilters.state || appFilters.city) && (
            <Button variant="outline-primary" onClick={clear} className="ms-2">
              Clear
            </Button>
          )}
        </Col>
      </Row>

      {/* Profile Cards */}
      <Row>
        {profiles.map((profile) => (
          <Col md={3} key={profile.name} className="my-2">
            <ProfileCard profile={profile} />
          </Col>
        ))}
      </Row>

      {/* No Profiles Found */}
      {!loading && profiles.length < 1 && (
        <h2 className="text-center py-5 my-5 text-secondary">
          No profiles found.
        </h2>
      )}

      {/* Loading Spinner */}
      {loading && (
        <div className="text-center py-5">
          <Spinner animation="border" />
        </div>
      )}
    </Container>
  );
};

export default FilterProfiles;
