import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config'; // Assuming you have the correct Firestore setup
import Swal from 'sweetalert2';
import firebase from 'firebase/compat/app'; // Import Firebase

const BotLogs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch bot logs when the component mounts
  useEffect(() => {
    const fetchBotLogs = async () => {
      const userId = firebase.auth().currentUser?.uid;
      if (!userId) {
        Swal.fire('User not authenticated');
        setLoading(false);
        return;
      }

      console.log('User ID:', userId); // Log the user ID for debugging

      try {
        // Access the 'bots' subcollection of the user
        const botRef = db.collection('jobs').doc(userId).collection('bots').doc('myBot');
        console.log('Bot Ref:', botRef); // Log the reference for debugging
        const botDoc = await botRef.get();
        
        if (botDoc.exists) {
          // Example: Assuming you have logs stored in the 'logs' field of the bot document
          const logsData = botDoc.data().logs || [];
          console.log("Bot logs data:", logsData); // Log the fetched logs data
          setLogs(logsData); // Update the state with the fetched logs
        } else {
          Swal.fire('No bot found for this user');
        }
      } catch (error) {
        console.error('Error fetching bot logs:', error);
        Swal.fire('Error fetching bot logs');
      } finally {
        setLoading(false);
      }
    };

    fetchBotLogs();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h3>Bot Logs</h3>
      {logs.length > 0 ? (
        <ul>
          {logs.map((log, index) => (
            <li key={index}>
              <strong>{new Date(log.timestamp?.seconds * 1000).toLocaleString()}</strong>: {log.message}
            </li>
          ))}
        </ul>
      ) : (
        <p>No logs available.</p>
      )}
    </div>
  );
};

export default BotLogs;
