import React, { useEffect, useState } from "react";
// import LogoRemoveBG from '../assets/LogoRemoveBG.png'; 
import avatarHolder from '../assets/avatarHolder.png'; 
// import AltJobListingSeeker from "../pages/AltJobListing";
import {
  createHashRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  // AffiliateDashboard,
  // Stripe,
  NotFoundPage,
  DashboardPage,
  // ContactUs,
  MyProfilePage,
  JobDashboard,
  CreateJobPage,
  // JobListingSeeker,
  // JobDetailsSeeker,
  // JobApplicationSeeker,
  JobDetailsRecruiter,
  // FilterProfiles,
  CompanyDetailsPage,
  CreateAltJobPage,
  AltJobDetailsRecruiter,
  // AltJobDetailsSeeker,

} from "../pages";
import { AppHeader} from "../components";
import { Col, Container, Row } from "react-bootstrap";
import { NavIcon } from "../assets/icons/navbar/images";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../redux/authSlice";
import { auth } from "../firebase-config";
import { getStorage, ref, getDownloadURL } from "firebase/storage";  // Import Firebase Storage functions
import firebase from "firebase/compat/app";
import { getAuth, signOut} from 'firebase/auth'; // Import Firebase authentication methods

const Wrapper = (props) => {
  const signinProvider = firebase.auth().currentUser?.providerData[0]?.providerId;
  const user = useSelector((state) => state.authReducer.auth);
  const profile = useSelector((state) => state.authReducer.user);
  // const [profileData, setProfileData] = useState({});
  const [avatarUrl, setAvatarUrl] = useState(avatarHolder);  // Use avatarHolder as default
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isCompany = useSelector((state) => state.authReducer.isCompany);

  const isActive = (route) => {
    return location.pathname === route || location.pathname.startsWith(route);
  };

  useEffect(() => {
    const fetchAvatarFromStorage = async (userId) => {
      if (signinProvider !== "facebook.com") {
        try {
          const storage = getStorage();
          let avatarRef;
  
          // Check if the user is a company or profile and adjust the avatar path accordingly
          if (isCompany) {
            avatarRef = ref(storage, `company/avatar/${userId}`);
          } else {
            avatarRef = ref(storage, `profile/avatar/${userId}`);
          }
  
          // Fetch the avatar URL and append a timestamp to avoid caching issues
          const avatarUrl = `${await getDownloadURL(avatarRef)}?v=${new Date().getTime()}`;
          setAvatarUrl(avatarUrl);
          localStorage.setItem("profilePictureUrl", avatarUrl);
        } catch (error) {
          setAvatarUrl(avatarHolder); // Fallback to default avatar
        }
      }
    };
  
    const storedAvatarUrl = localStorage.getItem("profilePictureUrl");
    if (storedAvatarUrl) {
      setAvatarUrl(storedAvatarUrl);
    } else if (userId) {
      fetchAvatarFromStorage(userId);
    }
  
    // Listen for profile updates and re-fetch the avatar
    const updateAvatarListener = () => {
      if (userId) {
        fetchAvatarFromStorage(userId);
      }
    };
  
    window.addEventListener("profileUpdated", updateAvatarListener);
  
    return () => {
      window.removeEventListener("profileUpdated", updateAvatarListener);
    };
  }, [userId, isCompany, signinProvider]);
  

  
  
  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserId(currentUser.uid);
    }
  }, []);

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(setLogin(false)); // Log out the user by setting login status to false
        navigate('/login'); // Redirect to login page
        console.log('User signed out successfully');
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserId(currentUser.uid);
      console.log('Logged in User ID:', currentUser.uid);
      console.log('Is Company:', isCompany);
      console.log('Is Profile:', !isCompany);
    }
  }, [isCompany]);
  

  return (
    <>
      <Container className="bg-light full-height" fluid>
        <Row>
          <Col md={3}>
            <div className="sidebar">
              <div className="profile-chip my-4">
                <div className="avatar">
                  <img
                    src={avatarUrl}  // Use the updated avatarUrl state
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = avatarHolder;  // Fallback image if avatar fails
                    }}
                    alt="User Avatar"
                  />
                </div>
                <div>
                  <h4
                    style={{
                      fontWeight: "800",
                      fontSize: "20px",
                      fontStyle: "sans-serif",
                    }}
                    className="name"
                  >
                    {profile?.name}
                  </h4>
                </div>
              </div>
              <div
                className={`${isActive("/dashboard") ? "active" : ""} sidebar-item`}
                onClick={() => navigate("/dashboard")}
              >
                <img src={NavIcon.ic_analytics} alt="" />
                <p>Dashboard</p>
              </div>
              {/* <div
                className={`${isActive("/stripe") ? "active" : ""} sidebar-item`}
                onClick={() => navigate("/stripe")}
              >
                <img src={NavIcon.ic_analytics} alt="" />
                <p>Subscription</p>
              </div> */}
              {/* <div
                className={`${isActive("/jobs") ? "active" : ""} sidebar-item`}
                onClick={() => navigate("/jobs")}
              >
                <img src={NavIcon.ic_cart} alt="" />
                <p>Job Listings</p>
              </div> */}
             
           {/* {isCompany && (
            <div className={`${isActive("/profiles") ? "active" : ""} sidebar-item`}
              onClick={() => navigate("/profiles")}
            >
              <img src={NavIcon.ic_user} alt="" />
              <p>Profiles</p>
            </div>
          )} */}
              {/* <div className={`${isActive("/affiliate-dashboard") ? "active" : ""} sidebar-item`}
                onClick={() => navigate("/affiliate-dashboard")}
              >
                <img src={NavIcon.ic_analytics} alt="" />
                <p>Affiliate Dashboard</p>
              </div> */}
              <div className="sidebar-item text-danger" onClick={logout}>
                <img src={NavIcon.ic_lock} alt="" />
                <p>Logout</p>
              </div>
            </div>
          </Col>
          <Col md={9}>
            <Row>
              <Col md={12} className="p-0">
                <div className="dash-header">
                  <AppHeader
                    avatar={avatarHolder}
                    name={user?.displayName}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="main-body">{props.component}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const router = createHashRouter(
  createRoutesFromElements(
    <>
      {/* <Route path="/" element={<Navigate to="/dashboard" />} /> */}
      <Route path="/*" element={<Navigate to="/dashboard" />} />

      <Route path="/dashboard" element={<Wrapper component={<DashboardPage />} />} />
      {/* <Route path="/contact" element={<Wrapper component={<ContactUs />} />} /> */}
      {/* <Route path="/jobs" element={<Wrapper component={<JobListingSeeker />} />} /> */}
      {/* <Route path="/jobs-alt" element={<Wrapper component={<AltJobListingSeeker />} />} />
      <Route path="/stripe" element={<Wrapper component={<Stripe />} />} />
      <Route path="/affiliate-dashboard" element={<Wrapper component={<AffiliateDashboard />} />} />
      <Route path="/profiles" element={<Wrapper component={<FilterProfiles />} />} /> */}
      <Route path="/profile" element={<Wrapper component={<MyProfilePage />} />} />
      <Route path="/company-details" element={<Wrapper component={<CompanyDetailsPage />} />} />
      <Route path="/company-details/:id" element={<Wrapper component={<CompanyDetailsPage />} />} />
      {/* <Route path="/profiles/:id" element={<Wrapper component={<MyProfilePage />} />} /> */}
      <Route path="/manage-trades">
        <Route path="" element={<Wrapper component={<JobDashboard />} />} />
        <Route path="create" element={<Wrapper component={<CreateJobPage />} />} />
        <Route path="create-alt" element={<Wrapper component={<CreateAltJobPage />} />} />
        {/* <Route path="your-applications" element={<Wrapper component={<JobApplicationSeeker />} />} /> */}
        {/* <Route path="details/:id" element={<Wrapper component={<JobDetailsSeeker />} />} /> */}
        {/* <Route path="details-alt/:id" element={<Wrapper component={<AltJobDetailsSeeker />} />} /> */}
        <Route path="status/:id" element={<Wrapper component={<JobDetailsRecruiter />} />} />
        <Route path="status-alt/:id" element={<Wrapper component={<AltJobDetailsRecruiter />} />} />
      </Route>
      <Route path="*" element={<Wrapper component={<NotFoundPage />} />} />
    </>
  )
);

const HomeRouter = () => {
  return (
    <>
      <div className="app-body">
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default HomeRouter;

