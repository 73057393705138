/* global FB */

import React, { useEffect, useRef, useState } from "react";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import { getAuth, FacebookAuthProvider, signInWithCredential, deleteUser } from "firebase/auth";
import "./Login.css";

import {
  CompanyService,
  FirebaseAuthService,
  ProfileService,
} from "../services";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { setAuth, setCompany, setLogin, setUser } from "../redux/authSlice";

const LoginPage = () => {
  const emailInput = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [scrollY, setScrollY] = useState(0);
  const [credentials, setCredentials] = useState({
    emailId: "",
    password: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
    credentials: "",
  });

//   useEffect(() => {
//     if (typeof FB !== "undefined") {
//       FB.getLoginStatus(function (response) {
//         console.log("Facebook SDK status:", response);
//       });
//     } else {
//       console.error("Facebook SDK not initialized yet.");
//     }
//   }, []);

  

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (emailInput && emailInput.current) emailInput.current.focus();
    }, 1000);
  }, []);

  const isValid = () => {
    let flag = true;
    let errorData = {};
    if (credentials.emailId === "") {
      flag = false;
      errorData.email = "Email cannot be blank";
    }
    if (credentials.password === "") {
      flag = false;
      errorData.password = "Password cannot be blank";
    }
    setError(errorData);
    return flag;
  };

  const onLogin = async () => {
    if (isValid()) {
      try {
        const res = await FirebaseAuthService.handleEmailSignin(
          credentials.emailId,
          credentials.password
        );
        if (res.user) {
          if (res.user.emailVerified) {
            localStorage.setItem("user", JSON.stringify(res.user));
            localStorage.setItem("isAuth", true);

            const userProfile = await ProfileService.getProfileDetails(
              res.user.uid
            );
            const companyProfile = await CompanyService.getCompanyDetails(
              res.user.uid
            );

            if (userProfile.exists) {
              dispatch(setCompany(false));
              dispatch(
                setUser({
                  ...userProfile.data(),
                  avatar: userProfile.data().avatar,
                  id: res.user.uid,
                })
              );
              navigate("/dashboard");
            } else if (companyProfile.exists) {
              dispatch(setCompany(true));
              dispatch(
                setUser({
                  ...companyProfile.data(),
                  avatar: companyProfile.data().avatar,
                  id: res.user.uid,
                })
              );
              navigate("/dashboard");
            } else {
              deleteUser(res.user);
              
              
Swal.fire("Please Register - Profile doesn't exist").then(() => {
  console.log("Navigation triggered");
  navigate("/create-account");
});

              
              return;
            }

            dispatch(setAuth(res.user));
            dispatch(setLogin(true));
          } else {
            Swal.fire({
              icon: "error",
              text: `Login Failed. Please verify your email`,
            });

            setTimeout(async () => {
              const lastVerificationTime = localStorage.getItem(
                "lastVerificationTime"
              );
              const currentTime = new Date().getTime();
              const throttleLimit = 300000; // 5 minutes

              if (
                !lastVerificationTime ||
                currentTime - lastVerificationTime > throttleLimit
              ) {
                await FirebaseAuthService.verifyEmail(res.user);
                localStorage.setItem("lastVerificationTime", currentTime);
                Swal.fire({
                  icon: "info",
                  text: `A verification email has been sent to ${res.user.email}`,
                });
              } else {
                Swal.fire({
                  icon: "info",
                  text: `Please wait before requesting another verification email`,
                });
              }
            }, 2000);
          }
        }
      } catch (e) {
        Swal.fire({
          icon: "error",
          text: `Login Failed. ${e.message}`,
        });
      }
    }
  };

  
  const onGoogle = async () => {
    try {
      const res = await FirebaseAuthService.handleGoogleLogin();
      if (res.user) {
        await handleUserLogin(res);
      }
    } catch (error) {
      Swal.fire("Failed to create account.", error.message);
    }
  };

  
  
  const handleUserLogin = async (res) => {
    try {
      const userProfile = await ProfileService.getProfileDetails(res.user.uid);
      const companyProfile = await CompanyService.getCompanyDetails(res.user.uid);
      
      if (!userProfile.exists && !companyProfile.exists && res.user.emailVerified) {
        deleteUser(res.user);
        Swal.fire("Please Register - Profile doesn't exist");
        navigate("/create-account");

        return;
      }

      if (userProfile.exists) {
        dispatch(setCompany(false));
        dispatch(setUser({
          ...userProfile.data(),
          avatar: userProfile.data().avatar,  // Fetch and store avatar in Redux
          id: res.user.uid,
        }));
      } else if (companyProfile.exists) {
        dispatch(setCompany(true));
        dispatch(setUser({
          ...companyProfile.data(),
          avatar: companyProfile.data().avatar,  // Fetch and store avatar in Redux
          id: res.user.uid,
        }));
      }

      localStorage.setItem("user", JSON.stringify(res.user));
      localStorage.setItem("isAuth", true);
      dispatch(setAuth(res.user));
      dispatch(setLogin(true));
      navigate("/dashboard");
    } catch (error) {
      Swal.fire("Failed to handle user login.", error.message);
    }
  };


  const onFacebook = () => {
    if (typeof FB !== 'undefined') {
      FB.login(function (response) {
        if (response.authResponse) {
          console.log('Facebook login success:', response);
  
          const { accessToken } = response.authResponse;
  
          // Authenticate with Firebase using the Facebook access token
          handleFacebookFirebaseLogin(accessToken);
        } else {
          console.log('Facebook login failed or canceled.');
        }
      }, { scope: 'email' });
    } else {
      console.error('Facebook SDK not initialized. Try again later.');
      setError({ ...error, credentials: 'Facebook login is currently unavailable. Please try again later.' });
    }
  };
  
  const handleFacebookFirebaseLogin = async (accessToken) => {
    try {
      // Step 1: Authenticate with Firebase using the Facebook access token
      const auth = getAuth();
      const credential = FacebookAuthProvider.credential(accessToken);
      // const FacebookUserID = FacebookAuthProvider.userID
      // console.log(FacebookUserID , 'fb id')
  
      const fbAuthResult = await signInWithCredential(auth, credential);
      const facebookUserId = fbAuthResult.user.providerData[0].uid;
  
      // Step 2: Now we have the Firebase `uid`, use it to check the user's profile in Firestore
      if (fbAuthResult.user) {
        const userExists = await checkUserExistsInFirebase(fbAuthResult.user.uid);
        console.log(fbAuthResult.user.providerData[0].uid, 'fb id')
  
        if (userExists) {
        await handleSuccessfulFacebookLogin(fbAuthResult.user.uid, accessToken);
        
        localStorage.setItem("facebookUserId", facebookUserId);  // Store in localStorage
        // const credential = FacebookAuthProvider.credentialFromResult(res);

        const facebookAccessToken = credential.accessToken;
        localStorage.setItem("facebookAccessToken", facebookAccessToken); 
        const profilePictureUrl = `https://graph.facebook.com/${facebookUserId}/picture?access_token=${facebookAccessToken}`;

        localStorage.setItem("profilePictureUrl", profilePictureUrl );

        

        } else {
          await deleteUserFunction(fbAuthResult.user);  // Delete the Firebase user
          Swal.fire("Please register on the site before logging in with Facebook.");
          Swal.fire("Please Register - Profile doesn't exist").then(() => {
            console.log("Navigation triggered");
            navigate("/create-account");
          });
          // Remove the app from the user's Facebook account
          FB.api("/me/permissions", "delete", function(response) {
            console.log("Removed app permissions from Facebook.");
          });
          

        }
      }
    } catch (error) {
      Swal.fire("Failed to login with Facebook.", error.message);
      console.error("Error during Facebook Firebase login:", error);
    }
  };
  
  // Now we check Firestore using the Firebase `uid`, not the Facebook `userID`
  const checkUserExistsInFirebase = async (uid) => {
    try {
      const userProfile = await ProfileService.getProfileDetails(uid);
      const companyProfile = await CompanyService.getCompanyDetails(uid);
    
      return userProfile.exists || companyProfile.exists;
    } catch (error) {
      console.error('Error checking user in Firebase:', error);
      return false;
    }
  };
  
  // Handle the rest of the login process once the user is authenticated with Firebase
  const handleSuccessfulFacebookLogin = async (uid,  accessToken) => {
    try {

      // console.log(fbAuthResult.user.uid, "fb id console")
     
      // Fetch user and company profiles from Firestore using Firebase `uid`
      const userProfile = await ProfileService.getProfileDetails(uid);
      const companyProfile = await CompanyService.getCompanyDetails(uid);
  
      // If neither profile nor company profile exists, prompt the user to register and remove the app from Facebook
      if (!userProfile.exists && !companyProfile.exists) {
        await deleteUserFunction({ uid });  // Delete the Firebase user
        Swal.fire("Please register before logging in.");
        
        // Remove the app from the user's Facebook account
        FB.api("/me/permissions", "delete", function(response) {
          console.log("Removed app permissions from Facebook.");
        });
        return;
      }
  
      // Set user data in Redux and localStorage based on whether they are a company or profile user
      if (userProfile.exists) {
        dispatch(setCompany(false));
        dispatch(setUser({ ...userProfile.data(), id: uid }));
      } else if (companyProfile.exists) {
        dispatch(setCompany(true));
        dispatch(setUser({ ...companyProfile.data(), id: uid }));
      }
  
      // Store user details in localStorage
      localStorage.setItem("user", JSON.stringify({ uid }));
      localStorage.setItem("isAuth", true);
      dispatch(setAuth({ uid }));
      dispatch(setLogin(true));

      

      

       // Store the Facebook profile picture URL in localStorage
       const profilePictureUrl = `https://graph.facebook.com/${uid}/picture?access_token=${accessToken}`;
       localStorage.setItem("profilePictureUrl", profilePictureUrl);
   
       // Redirect to the dashboard
       
 
      navigate("/dashboard");
      
  
      // Store the Facebook profile picture URL in localStorage
      // const profilePictureUrl = `https://graph.facebook.com/${uid}/picture?access_token=${accessToken}`;
      // localStorage.setItem("profilePictureUrl", profilePictureUrl);
  
      // Redirect to the dashboard
      
  
    } catch (error) {
      Swal.fire("Failed to complete login.", error.message);
      console.error("Error during Facebook login:", error);
    }
  };
  
  // Function to delete the Firebase user
  const deleteUserFunction = async (user) => {
    try {
      await deleteUser(user);  // Delete the user from Firebase
      console.log("User deleted successfully.");
    } catch (error) {
      console.error("Error during user deletion:", error.message);
    }
  };
  
  
  
  
  

  return (
    <>
      <section className="hero-section">
      <div
  className="hero-wrap"
  style={{
    backgroundPositionY: `${0.7 * scrollY}px`
  }}
>
  <div className="coin-front"></div>
  <div className="coin-back"></div>
          <div className="overlay"></div>
          <div className="hero-text">
            <div className="text-center col-lg-8">
              {/* <h1>Login to 1STOPSLP Portal</h1>
              <h3 className="py-3">Login</h3> */}
            </div>
          </div>
        </div>
      </section>
      <div className="full-page d-flex align-items-center justify-content-center">
        <div className="login-container"> {/* Same container styling as signup */}
          <h3 className="text-center">LOGIN</h3>
          <div className="login-form">
            <FloatingLabel
              controlId="email"
              label="Email address"
              className="login-input mb-2"
            >
              <Form.Control
                ref={emailInput}
                type="email"
                placeholder="name@example.com"
                value={credentials.emailId}
                onChange={(e) =>
                  setCredentials({ ...credentials, emailId: e.target.value })
                }
              />
              {error.email && <p className="error-input">{error.email}</p>}
            </FloatingLabel>
            <FloatingLabel
              controlId="password"
              label="Password"
              className="login-input mb-2"
            >
              <Form.Control
                type="password"
                placeholder="Password"
                value={credentials.password}
                onChange={(e) =>
                  setCredentials({ ...credentials, password: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") onLogin();
                }}
              />
              {error.password && <p className="error-input">{error.password}</p>}
            </FloatingLabel>
            <div className="d-grid my-3">
              {error.credentials && (
                <p className="error-input">{error.credentials}</p>
              )}
              <Button onClick={onLogin}>Login</Button>
              <p className="text-center m-2">Or</p>
              <Button
                className="my-2"
                variant="outline-primary"
                onClick={onGoogle}
                style={{
                  backgroundColor: '#4285f4',
                  color: 'white',
                  border: 'none',
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#d32f2f';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = '#4285f4';
                }}
              >
                Login with Google
              </Button>
              {/* <Button className="btn-fb my-2" onClick={onFacebook}>
                Login with Facebook
              </Button> */}
              <p className="my-3 text-center">
                Don't have an account?{' '}
                <span
                  className="text-primary btn-link"
                  onClick={() => navigate('/create-account')}
                >
                  Create new
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
};

export default LoginPage;