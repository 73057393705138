import React, { useState, useEffect, useCallback } from "react";
import { Card } from "react-bootstrap";

// Replace with your actual API key here or use environment variables
const API_KEY = 'TrhwsOAYLrTtXYjTTXElIUO9bUMb3q4mFxdBjscz';  // Add your API key for the quote API

const QuoteCard = ({ userId }) => {
  const [quote, setQuote] = useState(null);
  const [error, setError] = useState(null);

  // Fetch quotes based on category
  const fetchQuotes = useCallback(async () => {
    const category = 'success';
    const apiUrl = `https://api.api-ninjas.com/v1/quotes?category=${category}`;

    try {
      const response = await fetch(apiUrl, {
        headers: {
          'X-Api-Key': API_KEY
        }
      });

      console.log('API Response:', response);  // Log full response for debugging

      if (response.ok) {
        const data = await response.json();
        const fetchedQuote = data[0]?.quote;

        console.log('Fetched Quote:', fetchedQuote);  // Log the quote

        // Save the quote and current timestamp in local storage with user-specific keys
        localStorage.setItem(`quote_${userId}`, fetchedQuote);
        localStorage.setItem(`lastQuoteFetch_${userId}`, Date.now());

        setQuote(fetchedQuote);  // Set the quote in state
      } else {
        // Log detailed error message if the response is not OK
        const errorMsg = `Error fetching quotes: ${response.status} ${response.statusText}`;
        console.error(errorMsg);  // Log error details
        setError(errorMsg);  // Set error in state
      }
    } catch (err) {
      // Catch and log any network or other fetch-related errors
      console.error('Network or Fetch Error:', err.message);  // Log the error
      setError(`Network error: ${err.message}`);  // Set network error in state
    }
  }, [userId]); 

  // Check if the last quote fetch for this user was within the last 24 hours
  const checkAndFetchQuote = useCallback(() => {
    const storedQuote = localStorage.getItem(`quote_${userId}`);
    const lastFetch = localStorage.getItem(`lastQuoteFetch_${userId}`);
    const currentTime = Date.now();
    const oneDay = 24 * 60 * 60 * 1000;  // 24 hours in milliseconds

    if (lastFetch && (currentTime - lastFetch < oneDay)) {
      // If within 24 hours, use the stored quote for this user
      console.log('Using stored quote:', storedQuote);  // Log stored quote
      setQuote(storedQuote);
    } else {
      // If more than 24 hours, fetch a new quote
      fetchQuotes();
    }
  }, [userId, fetchQuotes]); // Only recreate the function if userId or fetchQuotes changes

  useEffect(() => {
    if (userId) {
      checkAndFetchQuote();  // Only fetch if userId is available
    }
  }, [userId, checkAndFetchQuote]);

  return (
    <Card>
      <Card.Body>
        <Card.Title>Daily Quote</Card.Title>
        {quote ? (
          <Card.Text>{quote}</Card.Text>
        ) : (
          <Card.Text>Loading quote...</Card.Text>
        )}
        {error && <Card.Text style={{ color: "red" }}>{error}</Card.Text>}
      </Card.Body>
    </Card>
  );
};

export default QuoteCard;
