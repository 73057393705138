import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { db } from "../../firebase-config"; // Firestore config
import { Card, Typography } from '@mui/material';

const AffiliatePerfMetrics = () => {
    const profile = useSelector((state) => state.authReducer.user); // Get profile data
    const [totalClicks, setTotalClicks] = useState(0);
    const [referralSignups, setReferralSignups] = useState(0);
    const [purchases, setPurchases] = useState(0);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchPerformanceMetrics = async () => {
            try {
                // Fetch affiliate data where this profile is the affiliate
                const jobAffiliateLinksRef = db.collectionGroup('jobAffiliateLinks')
                    .where('affiliateId', '==', profile.username); // Assuming `username` is the identifier for profiles

                const jobSnapshot = await jobAffiliateLinksRef.get();

                let clicks = 0;
                let signups = 0;

                jobSnapshot.docs.forEach(doc => {
                    const data = doc.data();
                    clicks += data.totalAuthClicks || 0;
                    signups += data.signups || 0;
                });

                setTotalClicks(clicks);
                setReferralSignups(signups);
                // Assuming you want to fetch purchases from somewhere else, set it accordingly
                setPurchases(0); // Update this with your logic if needed

                setLoading(false);
            } catch (error) {
                console.error('Error fetching performance metrics:', error);
                setErrorMessage('Failed to fetch performance metrics.');
                setLoading(false);
            }
        };

        if (profile && profile.username) {
            fetchPerformanceMetrics();
        } else {
            setErrorMessage('Profile not found.');
            setLoading(false);
        }
    }, [profile]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (errorMessage) {
        return <div style={{ color: 'red' }}>{errorMessage}</div>;
    }
    
    return (
        <Card style={{ padding: '20px', marginBottom: '20px' }}>
            <Typography variant="h5" style={{ marginBottom: '10px', color: 'gray' }}>
                Performance Metrics
            </Typography>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                <li>Total Clicks: {totalClicks}</li>
                <li>Referral Sign-ups: {referralSignups}</li>
                <li>Purchases: {purchases}</li>
            </ul>
        </Card>
    );
};

export default AffiliatePerfMetrics;
