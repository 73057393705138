import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router";
import avatarHolder from '../assets/avatarHolder.png'; 
// import { Typography, Box, Divider } from "@mui/material";
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import SpotLogo from '../assets/SpotLogo.png'; 


const AppHeader = (props) => {
  const location = useLocation();
  const getPageTitle = () => {
    switch (location.pathname) {
      // case "/affiliate-dashboard":
      //   return "Affiliate Dashboard";
      // case "/stripe-onboard":
      //   return "Stripe Onboarding";
      // case "/stripe":
      //   return "Stripe Dashboard";
      case "/dashboard":
        return "Dashboard";
      case "/jobs":
        return "Jobs Listing";
      case "/profile":
        return "User Profile";
      // case "/profiles":
      //   return "Profiles";
      case "/company-details":
        return "Company Details";
      case "/manage-trades":
        return "Trading Dashboard";
      case "/manage-trades/create":
        return "Create Bot Trade";
      case "/manage-trades/create-alt":
        return "Create Trade";
      default:
        // if (location.pathname.startsWith("/profiles/")) {
        //   return "User Profile";
        // }
        if (location.pathname.startsWith("/manage-trades/details")) {
          return "Trade Overview";
        }
        if (location.pathname.startsWith("/manage-trades/status")) {
          return "Trade Status";
        }
        // if (location.pathname.startsWith("/manage-trades/your-applications")) {
        //   return "Your Applied Jobs";
        }
        return "Not Found";
  
  };
  return (
   
        <Container fluid className="app-header px-2 py-4">
      <Row className="px-2 align-items-center">
        <Col sm={6}>
          <h2 style={{ color: '#95bbdf', marginLeft: '35px', fontSize: '35px', fontWeight: '700' }} className="page-title">{getPageTitle()}</h2>
        </Col>
      
        {/* <Box display="flex" alignItems="center">
  <DashboardIcon sx={{ marginRight: 2 }} />
  <Typography variant="h4" component="h2" sx={{ color: 'text.primary', fontWeight: 'medium' }}>

    {getPageTitle()}
  </Typography>
</Box>
<Divider sx={{ marginTop: 1 }} /> */}
          <Col sm={6} className="text-end">
          {/* <div style={{  marginRight: '30px'}}>
            <span>
            <img 
  src={avatarHolder} // Fixed image URL for avatar
  alt="User Avatar"
  style={{
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    objectFit: "cover"
  }}
/>

            </span>
          </div> */}
        </Col>
      </Row>
    </Container>
  );

}
export default AppHeader;