import React from "react";
import { Container, Grid, Typography, Box, Link, IconButton } from "@mui/material";
import { Facebook, Twitter, LinkedIn } from "@mui/icons-material";
import { useNavigate } from "react-router-dom"; // For navigation

export default function Footer() {
  const navigate = useNavigate();

  // Function to handle button clicks for navigation
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Box component="footer" sx={{ backgroundColor: '#1c2331', color: '#fff', py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>

          {/* Quick Links - Customers */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom>
              Customers
            </Typography>
            <Link
              href="#"
              color="inherit"
              underline="hover"
              onClick={() => handleNavigation("/jobs")}
            >
              Job Listing
            </Link>
            <br />
            <Link
              href="#"
              color="inherit"
              underline="hover"
              onClick={() => handleNavigation("/profiles")}
            >
              Profiles
            </Link>
          </Grid>

          {/* Quick Links - Company */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom>
              Company
            </Typography>
            <Link
              href="#"
              color="inherit"
              underline="hover"
              onClick={() => handleNavigation("/about")}
            >
              About Us
            </Link>
            <br />
            <Link
              href="#"
              color="inherit"
              underline="hover"
              onClick={() => handleNavigation("/careers")}
            >
              Careers
            </Link>
            <br />
            <Link
              href="#"
              color="inherit"
              underline="hover"
              onClick={() => handleNavigation("/contact")}
            >
              Contact Us
            </Link>
          </Grid>

          {/* Further Information */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom>
              Further Information
            </Typography>
            <Link
              href="#"
              color="inherit"
              underline="hover"
              onClick={() => handleNavigation("/terms")}
            >
              Terms &amp; Conditions
            </Link>
            <br />
            <Link
              href="#"
              color="inherit"
              underline="hover"
              onClick={() => handleNavigation("/privacy")}
            >
              Privacy Policy
            </Link>
          </Grid>

          {/* Social Media Links */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box>
              <IconButton color="inherit" href="https://facebook.com" target="_blank">
                <Facebook />
              </IconButton>
              <IconButton color="inherit" href="https://twitter.com" target="_blank">
                <Twitter />
              </IconButton>
              <IconButton color="inherit" href="https://linkedin.com" target="_blank">
                <LinkedIn />
              </IconButton>
            </Box>
          </Grid>

          {/* Join FB Group - Card Component on the Right */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: '#194f96 !important',
                color: '#fff !important',
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
              }}
            >
              <Typography variant="h6">
                Join FB Group
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#00838d !important',
                  color: '#fff !important',
                  ml: 2,
                  paddingX: 2,
                  borderRadius: 2,
                  textTransform: 'uppercase',
                  '&:hover': {
                    backgroundColor: '#006b73',
                  },
                }}
                onClick={() => handleNavigation("/join")}
              >
                Join
              </Button>
            </Paper>
          </Grid> */}
        </Grid>

        {/* Developer Attribution */}
        <Box mt={4} textAlign="center">
          <Typography variant="body2" color="white">
            All Rights Reserved &copy; 2024
          </Typography>
          {/* <Typography variant="body2" color="white" align="center">
  Made with <span role="img" aria-label="heart">❤️</span> by an SLP, for SLPs
</Typography> */}

        </Box>
      </Container>
    </Box>
  );
}
