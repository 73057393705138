import React from "react";
import {
  createHashRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import {
  LoginPage,
  Home,
  SignUpPage,
  // ContactUs,
  // MyProfilePage,
  // JobListingPublic,
  // JobDetailsPublic,
  // AltListingPublic,
  // AltJobDetailsPublic,
 
} from "../pages";
import { Footer, SiteNav } from "../components";
import  RouteHandler from '../components/affiliate/RouteHandler';

const Wrapper = (props) => {
  return (
    <>
      <SiteNav />
      {props.component}
      <Footer />
    </>
  );
};

const router = createHashRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Wrapper component={<Home />} />} />
      {/* <Route path="contact" element={<Wrapper component={<ContactUs />} />} /> */}
      <Route path="login" element={<Wrapper component={<LoginPage />} />} />
      {/* <Route path="login" element={<Wrapper component={LoginPage} />} /> */}

      <Route
        path="create-account"
        element={<Wrapper component={<SignUpPage />} />}
      />
      {/* <Route
        path="profiles/:id"
        element={<Wrapper component={<MyProfilePage />} />}
      /> */}
      {/* <Route
        path="/jobs"
        element={<Wrapper component={<JobListingPublic />} />}
      /> */}

<Route
        path="route-handler"
        element={<Wrapper component={<RouteHandler/>} />}
      />
      {/* <Route
        path="jobs/:id"
        element={<Wrapper component={<JobDetailsPublic />} />}
      /> */}
      {/* <Route
        path="/jobs-alt"
        element={<Wrapper component={<AltListingPublic />} />}
      /> */}
      {/* <Route
        path="manage-trades/details/:id"
        element={<Wrapper component={<JobDetailsPublic />} />}
      /> */}
      {/* <Route
        path="manage-trades/details-alt/:id"
        element={<Wrapper component={<AltJobDetailsPublic />} />}
      /> */}
      <Route path="*" element={<Navigate to={"/"} />} />
    </>
  )
);

const AuthRouter = () => {
  return (
    <>
      <div className="app-body">
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default AuthRouter;
