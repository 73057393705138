// import firebase from "firebase/compat/app";
// //  import { initializeApp } from 'firebase/app';
// import "firebase/compat/auth";
// import "firebaseui/dist/firebaseui.css";
// // import setIsAuth from  "./components/SiteNavLogin";
// // import SiteNavLogin from "./components/SiteNavLogin";
// import "firebase/compat/firestore";
// import { getAuth, GoogleAuthProvider } from "firebase/auth";
// import "firebase/compat/storage";
// import { getPerformance } from "firebase/performance";
// // import { initializeFirestore } from "firebase/firestore";
// import "firebase/compat/functions"; 

// // Set Firebase auth persistence to LOCAL

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// export const firebaseConfig = {
//   apiKey: "AIzaSyBmvvO7nqjUxIp-9zU7a1Ea037Y3-eGkAQ",
//   authDomain: "kontha-partners.firebaseapp.com",
//   projectId: "kontha-partners",
//   storageBucket: "kontha-partners.firebasestorage.app",
//   messagingSenderId: "850938720952",
//   appId: "1:850938720952:web:8e4c6c02afaccde7677a4d",
//   measurementId: "G-7JCE1RN7P2"
// };

// const firebaseApp = firebase.initializeApp(firebaseConfig);
// export const perf = getPerformance(firebaseApp);
// export const app = firebase.initializeApp(firebaseConfig);
// export const db = firebaseApp.firestore();



// // export const db = initializeFirestore(firebaseApp, { experimentalAutoDetectLongPolling: true });
// export const storage = firebaseApp.storage();
// export const auth = getAuth(app);
// export const provider = new GoogleAuthProvider();
// export const functions = firebase.functions();

// // functions.useEmulator("localhost", 5001);
// // if (window.location.hostname === 'localhost') {
// //   functions.useEmulator("localhost", 3001); // Use the port where the emulator is running
// // }
// // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import { getPerformance } from "firebase/performance";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyBmvvO7nqjUxIp-9zU7a1Ea037Y3-eGkAQ",
  authDomain: "kontha-partners.firebaseapp.com",
  projectId: "kontha-partners",
  storageBucket: "kontha-partners.firebasestorage.app",
  messagingSenderId: "850938720952",
  appId: "1:850938720952:web:8e4c6c02afaccde7677a4d",
  measurementId: "G-7JCE1RN7P2"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Initialize Firebase services
export const perf = getPerformance(firebaseApp);
export const db = firebaseApp.firestore();
export const storage = firebaseApp.storage();
export const auth = getAuth(firebaseApp);
export const provider = new GoogleAuthProvider();
export const functions = firebase.functions();

// Optional: Local emulator setup (if testing locally)
// functions.useEmulator("localhost", 5001);

