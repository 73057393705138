import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { DashIcons } from "../assets/icons/dashIcons";
const ProfileCard = ({ profile }) => {
  const navigate = useNavigate();
  return (
    <div className="profile-card">
      <div className="images">
        <div className="cover-img">
          <img
            src={
              profile?.coverPhoto ||
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9PJscu_d_qoxDIWQnCEU-KF-6WpzI0hbMS-TmuUeaWw&s"
            }
            alt={profile.name}
          />
        </div>
        <div className="profile-img">
          <img
            className={profile.active === "YES" ? " active-p" : "-img"}
            src={
              profile?.avatar ||
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6klOi0UrG5l8Zhn8dnB3eJV_npa5aXJJk08pLp0yPvg&s"
            }
            alt={profile.name}
          />
         {profile.active === "YES" && (
  <img src={DashIcons.eye} className="active-looking" alt="" />
)}{" "}

        </div>
      </div>
      <div className="profile-body">
        <div>
          <h4>{profile.name}</h4>
          <p className="uname">@{profile.username}</p>
          <p className="place">{profile.place}</p>
          <p className="place">{profile.email}</p>
        </div>
        <div className="d-grid">
          <Button onClick={() => navigate(`/profiles/${profile.id}`)}>
            View Profile
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ProfileCard;
