import React, { useState } from "react";
// import firebase from "firebase/compat/app";
import BotLogs from "../components/BotLogs"; // Import the BotLogs component

import {  Container, Row, Col, ListGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";

import "./JobDetails.css";
import { AppBackgrounds } from "../assets/background/background";


const JobDetailsRecruiter = () => {
  // const navigate = useNavigate();
 
  const [jobDetails, setDetails] = useState();
  // const [seekers, setSeekers] = useState([]);




  // const getJobDetails = useCallback(async () => {
  //   const jobId = location.pathname.split("/").pop();
  //   try {
  //     const res = await JobService.getJobDetails(jobId);
  //     const details = res.data();
  //     const applicantsSnapshot = await res.ref.collection("applicants").get();
  //     const seekersPromises = applicantsSnapshot.docs.map(async (applicantDoc) => {
  //       const applicantData = applicantDoc.data();
  //       const profile = await ProfileService.getProfileDetails(applicantDoc.id);
  //       return { ...applicantData, ...profile.data() };
  //     });
  //     const s = await Promise.all(seekersPromises);
  //     setDetails({
  //       ...details,
  //       jobId: jobId,
  //       applicants: applicantsSnapshot.docs.map((x) => x.data()),
  //     });
  //     setSeekers(s);
  //   } catch (error) {
  //     console.log(error);
  //     Swal.fire({ text: "Error while fetching Job Details" });
  //   }
  // }, [location.pathname]); // Memoize with the required dependencies
  
  // useEffect(() => {
  //   getJobDetails();
  // }, [getJobDetails]); // Add the memoized function to the dependency array
  
  


  // const closeReq = async () => {
  //   try {
  //     await JobService.closeJob(jobDetails.jobId); // Remove the unused 'res'
  //     setDetails({ ...jobDetails, status: "closed" });
  //     Swal.fire({ text: "Requirement Closed" });
  //     await getJobDetails(); // Call getJobDetails after closing the job
  //   } catch (error) {
  //     Swal.fire({ text: "Error while closing requirement" });
  //   }
  // };
  

//   const selectSeeker = async (id) => {
//     try {
//         console.log('ID (clickedBy) in selectSeeker:', id);
        
//         // Select the seeker by updating Firestore
//         await JobService.selectSeeker(jobDetails.jobId, id);
//         console.log(`Seeker with ID ${id} selected for job ${jobDetails.jobId}.`);

//         // Fetch the user's email from the profile collection
//         const profileRef = db.collection('profile').doc(id);
//         const profileDoc = await profileRef.get();

//         if (!profileDoc.exists) {
//             throw new Error("User profile not found");
//         }

//         const userEmail = profileDoc.data().email;
//         console.log("Fetched user email:", userEmail);

//         // Use jobDetails to get the recruiter/company details
//         const companyId = jobDetails.recruiter;
//         const companyDoc = await CompanyService.getCompanyDetails(companyId);

//         if (!companyDoc.exists) {
//             throw new Error("Company not found");
//         }

//         const companyEmail = companyDoc.data().email;
//         const company = companyDoc.data().name;
//         console.log("Fetched company details:", { company, companyEmail });

//         // Send the email notification to the applicant
//         const emailContent = {
//             to: userEmail,
//             jobDetails: {
//                 title: jobDetails.title,
//                 location: jobDetails.state,
//                 jobId: jobDetails.jobId,
//             },
//             email: companyEmail,
//             company,
//         };
        
//         console.log("Sending email with content:", emailContent);
//         const sendEmailFunction = functions.httpsCallable('sendSelectionEmailV3');
//         await sendEmailFunction(emailContent);
//         console.log("Email sent successfully to", userEmail);

//         // Look for the affiliate click related to this candidate and job
//         const affiliateClickRef = db.collection('companies')
//             .doc(companyId)
//             .collection('jobAffiliateLinks')
//             .where('jobId', '==', jobDetails.jobId); // Querying for jobAffiliateLinks with matching jobId

//         const affiliateSnapshot = await affiliateClickRef.get();

//         if (!affiliateSnapshot.empty) {
//             const jobAffiliateLinkDoc = affiliateSnapshot.docs[0];
//             const affiliateClicksCollectionRef = jobAffiliateLinkDoc.ref.collection('affiliateClicks');

//             const clickSnapshot = await affiliateClicksCollectionRef.where('clickedBy', '==', id).get(); // Check for clicks by this candidate

//             if (!clickSnapshot.empty) {
//                 const clickData = clickSnapshot.docs[0].data();
//                 const affiliateId = clickData.affiliateId;

//                 console.log("Found affiliate ID for signup update:", affiliateId);

//                 // Update the jobAffiliateLinks for this job
//                 await jobAffiliateLinkDoc.ref.update({
//                     signups: firebase.firestore.FieldValue.increment(1),
//                 });
//                 console.log(`Updated signups for affiliate ${affiliateId}.`);
//             } else {
//                 console.log("No affiliate clicks found for the selected candidate.");
//             }
//         } else {
//             console.log("No Job Affiliate Link found for the job ID:", jobDetails.jobId);
//         }

//         Swal.fire({ text: "Applicant notified by email and affiliate signup updated." });
        
//     } catch (error) {
//         console.error("Error while selecting candidate or sending email:", error.message || error);
//         Swal.fire({ text: "Error while selecting candidate or sending email." });
//     }
// };



  
  
  
  
  
  

  return (
    <>
      <Container style={{ fontFamily: "MontSerrat" }}>
        <Row>
          <Col md={6}>
            <h2 style={{ fontWeight: "bold" }}>{jobDetails?.title}</h2>
            <div
  style={{
    borderRadius: "10px",
    boxShadow: "5px 5px 20px #0005",
    overflow: "hidden",
    width: '100%',  // Ensures full width
    height: '300px', // Sets the fixed height for the container
    maxHeight: "300px", // Ensures the container height doesn't exceed 300px
  }}
>
  <img
    src={`https://firebasestorage.googleapis.com/v0/b/kontha-partners
.appspot.com/o/jobs%2F${jobDetails?.jobId}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`}
    alt = ""
    onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = AppBackgrounds.bg_1;
    }}
    style={{
      width: "100%",
      height: "100%", // Makes sure the image covers the entire container
      objectFit: "cover", // Ensures the image fills the container while maintaining its aspect ratio
      objectPosition: "center", // Centers the image within the container
    }}
  />
</div>

            <div className="my-3"></div>
          </Col>
          <Col md={6}>
            <h3>Job Description</h3>
            <hr />
            <p>{jobDetails?.description}</p>
            {/* <div className="d-grid">
              {jobDetails?.status === "active" && (
                <Button variant="danger" onClick={closeReq}>
                  Close Requirement
                </Button>
              )}
              {jobDetails?.status === "closed" && <Button>Closed </Button>}
            </div> */}
          </Col>
        </Row>
        {/* <Row>
          <h3>Applied Candidates</h3>
          <hr />
        {seekers.map((item) => (
            <Col md={6} className="my-2 mb-5">
              <div
                className="profile-chip"
                style={item.selected ? { backgroundColor: "#40D06530" } : {}}
              >
                <div className="avatar">
                  <img src={item.avatar} alt = ""/>
                </div>
                <div className="name">
                  <h6>{item.name}</h6>
                  <p>{item.email}</p>
                </div>
              </div>
              <div className="d-grid">
                <div className="btn-group " role="group">
                  {jobDetails.status === "active" ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => selectSeeker(item.id)}
                    >
                      Select Candidate
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate("/profiles/" + item.id)}
                  >
                    View Profile
                  </button>
                </div>
              </div>
            </Col>
          ))}
        </Row> */}
        {/* <div
              style={{
                
                // display:"flex",
                borderRadius: "30px",
                boxShadow: "5px 5px 20px #0003",
                overflow: "hidden",
                padding: "20px",
                
                // marginLeft:"25px",
                backgroundColor:"white"
              }}
            > */}
        <Row className="justify-content-center">
          <Col md={12}>
            <div
              style={{
                borderRadius: "30px",
                boxShadow: "5px 5px 20px #0003",
                minWidth: "316px",
                padding: "20px",
                margin: "40px",
                marginTop: "20px",
                marginRight: "none",
                backgroundColor: "#F6F6F6",
              }}
            >
              <h3>Brief Details</h3>
              <hr />
              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Created Date
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {jobDetails?.creationTime?.split("T")[0]}
                </ListGroup.Item>
              </ListGroup>
              <ListGroup as="ul" horizontal className="list-group">
                <ListGroup.Item as="li" className="list-group-left">
                  Status
                </ListGroup.Item>
                <ListGroup.Item as="li" className="list-group-right">
                  {" "}
                  {jobDetails?.status}
                </ListGroup.Item>
              </ListGroup>

              {/* <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Company Size
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.companySize}
                </ListGroup.Item>
              </ListGroup> */}

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Bot Trade
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.title}
                </ListGroup.Item>
              </ListGroup>
                 {/* Logs Section */}
         {/* Add Bot Logs under the last ListGroup */}
      <Row>
        <Col md={12}>
          <div style={{ marginTop: '20px' }}>
            <BotLogs />  {/* Render the BotLogs component */}
          </div>
        </Col>
      </Row>

            
            </div>
          </Col>
        </Row>
        {/* </div> */}
      </Container>
    </>
  );
};

// const InfoCard = (props) => {
//   return (
//     <div
//       style={{
//         borderBottom: "1px solid #AAA3",
//         padding: "5px",
//         marginBottom: "10px",
//         fontSize: "20px",
//         display: "flex",
//       }}
//     >
//       <div style={{ width: "35%" }}>{props?.label}</div>
//       <div style={{ width: "65%" }}> {props.value}</div>
//     </div>
//   );
// };

export default JobDetailsRecruiter;