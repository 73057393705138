import React, { useState } from "react";
import firebase from "firebase/compat/app";
import { Spinner } from "react-bootstrap";
import { Button, Grid, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { functions, db } from "../../firebase-config"; // Import Firebase functions



// Placeholder Card Component
// const JobCard = ({ job }) => (
//   <div className="job-card" style={{ border: "1px solid #ddd", padding: "20px", margin: "10px" }}>
//     <h4>Job Title: {job.title || "Placeholder Title"}</h4>
//     <p>Description: {job.description || "Placeholder Description"}</p>
//     <p>Status: {job.status || "Active"}</p>
//     <p>API Key: {job.apiKey || "Placeholder API Key"}</p>
//   </div>
// );

const CreateJob = () => {
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    apiKey: "",
  });
  const [submittedJob, setSubmittedJob] = useState(null); // State to store job card
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.authReducer.user);

  const validateForm = () => {
    let errors = {};
    if (!formData.title) errors.title = "Trade title is required";
    if (!formData.description) errors.description = "Trade description is required";
    if (!formData.apiKey) errors.apiKey = "Api Key is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Validate form before submitting
    if (!validateForm()) {
      Swal.fire({ text: "Please fill in all required fields." });
      return;
    }
  
    setLoading(true);
    const userId = userProfile ? userProfile.id : null;
    const tradeData = {
      title: formData.title,
      description: formData.description,
      apiKey: formData.apiKey,
      recruiter: userId,
      company: userProfile.name,
      status: "active",
    };
  
    try {
      // Call Firebase function to create job and start the bot
      const startUserBotFunction = functions.httpsCallable("startUserBot");
      const res = await startUserBotFunction(tradeData); // Call Firebase function
  
      if (res.data && res.data.jobId) {
        console.log("Job created:", res.data.jobId);
  
        // Add log to the 'logs' subcollection after job is created
        const jobRef = db.collection('jobs').doc(res.data.jobId);
        const logsRef = jobRef.collection('logs');
        await logsRef.add({
          message: 'Job created and bot started',
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
  
        Swal.fire({ text: "Job created and log added successfully!" });
        navigate("/manage-trades");
      }
    } catch (error) {
      console.error("Error creating job:", error);
      Swal.fire({ text: `Error creating job: ${error.message}` });
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  

  return (
    <form className="my-5" onSubmit={handleSubmit} style={{ width: "60%", margin: "auto" }}>
      <div
        style={{
          borderRadius: "15px",
          boxShadow: "5px 5px 20px #0003",
          overflow: "hidden",
          padding: "20px",
          margin: "40px",
          minWidth: "316px",
          backgroundColor: "#f6f6f6",
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Trade Title"
              onChange={(e) => handleChange("title", e.target.value)}
              value={formData.title}
              fullWidth
              required
              error={formErrors.title}
              helperText={formErrors.title ? "Trade title is required" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Description"
              onChange={(e) => handleChange("description", e.target.value)}
              value={formData.description}
              fullWidth
              required
              error={formErrors.description}
              helperText={formErrors.description ? "Trade description is required" : ""}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Api Key"
              onChange={(e) => handleChange("apiKey", e.target.value)}
              value={formData.apiKey}
              fullWidth
              required
              error={formErrors.apiKey}
              helperText={formErrors.apiKey ? "Api Key is required" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <center>
              <Button variant="contained" size="large" color="primary" type="submit" disabled={loading}>
                {loading ? "Loading..." : "Submit"}
              </Button>
              {loading && <Spinner animation="border" role="status" style={{ marginLeft: "10px" }} />}
            </center>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default CreateJob;
