import React, { useEffect, useState } from "react";
import { Container, Grid, Card, CardMedia, CardContent, Typography, Box,  Divider} from "@mui/material";
// import { Facebook, Twitter, LinkedIn } from '@mui/icons-material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PostAddIcon from '@mui/icons-material/PostAdd';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShareIcon from '@mui/icons-material/Share';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import GitHubIcon from '@mui/icons-material/GitHub';

import { DashIcons } from "../assets/icons/dashIcons";

import "./Home.css";
import { Col, Row } from "react-bootstrap";
// import slpMarket from "../assets/1stop1Removebg.png";

// import thumbsHP from "../assets/thumbsHPpng";
const Home = () => {
  // const myRef = React.useRef(null)

  
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="home-container">
      <section className="hero-section">
      <div
  className="hero-wrap"
  style={{
    backgroundPositionY: `${0.7 * scrollY}px`
  }}
>
  <div className="coin-front"></div>
  <div className="coin-back"></div>

 
  <div className="overlay"></div>


          <div style = {{marginTop: "105px"}}className="hero-text">
            <div className="text-center"
            >
               <div style={{
              // fontWeight: "0", fontSize: "75px", fontFamily: "Akzidenz-Grotesk"}}>1Stop4SPOT
              fontWeight: "925", fontSize: "75px", fontFamily: "Sans"}}>
              </div> 
               <div style={{
               fontWeight: "bold", fontSize: "35px", fontStyle: "italic" }}className="py-3">
             {/* "Jobs for SLPS, OTs and More to Come!" */}
             {/* 1Stop 4 Speech Paths and OTs */}
               </div>
              {/* <h3 className="py-3">
                Post Jobs to FB!
              </h3> 
              <h3 className="py-3">
                 SLP Marketplace!
              </h3>   */} 
               <div className="text-center">
                 {/* <Button  onClick={scrollDiv}
                 variant="light "
                  style={{
                   
                    marginTop: "45px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    borderRadius: "15px",
                    textTransform: "uppercase",
                    color: "#194F96",
                    fontSize: "26px"
                    
                  }}
                  // size="lg"
                >
                  Explore
                </Button>  */}

              </div> 
            </div>
          </div>
        </div>
      </section>

    
            {/* <Col  md={12}>
              <span style={{fontSize:"25px"}}className="tag">Services</span>
            <h2 className="heading my-2">Solutions for SLPs</h2> 
            </Col> */}
       
      {/* Updated Services Section */}
    
     


     



 









         

        
     
      {/* <section className="pricing-section myl ">
        <Container>
          <Row className="my-4">
            <Col md={12} className="text-center">
              <span className="tag">Pricing</span>
              <h2 className="heading my-2">
                Choose A Plan That's right For You
              </h2>
            </Col>
          </Row>
          <Row className="myl align-items-center justify-content-evenly">
            <Col md={3}>
              <div className="card1 pricing-card">
                <h3>Basic Plan</h3>
                <h2>$99/Year</h2>
                <ul>
                  <li>Access to Job Listings</li>
                  <li>Limited Support</li>
                  <li>Community Access</li>
                </ul>
                <div className="my-2 px-2 d-grid">
                  <Button>Buy Now</Button>
                </div>
                <div className="go-corner">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="card1 pricing-card">
                <h3>Pro Plan</h3>
                <h2>$299/Year</h2>
                <ul>
                  <li>Career Guidance</li>
                  <li>Priority Support</li>
                  <li>Exclusive Webinars</li>
                </ul>
                <div className="my-2 px-2 d-grid">
                  <Button>Buy Now</Button>
                </div>
                <div className="go-corner">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="card1 pricing-card">
                <h3>Advance</h3>
                <h2>$399/Year</h2>
                <ul>
                  <li>Unlimited Job Listings</li>
                  <li>24/7 Concierge Support</li>
                  <li>VIP Networking Events</li>
                </ul>
                <div className="my-2 px-2 d-grid">
                  <Button>Buy Now</Button>
                </div>
                <div className="go-corner">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <div className="m-5 p-5"></div>
    </div>
  );
};

export default Home;
