import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import "./SiteNav.css";
// import logo from "../assets/LogoRemoveBG.png";
import { Button } from "react-bootstrap";

function SiteNav({ setIsAuth }) {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const [showMenu, setMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      if (currentPosition > 300) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeMenu = () => {
    setTimeout(() => {
      setMenu(false);
    }, 100);
  };

  return (
    <header
      className={
        scrolled
          ? "container-fluid site-nav scrolled"
          : "container-fluid site-nav"
      }
    >
      <div
        className="row header-contact"
        style={{ display: scrolled ? "none" : "flex" }}
      >
        <div className="col-6"></div>
        <div className="col-6 text-end">
          {/* Updated social media links with valid href */}
          <a href="https://www.facebook.com" className="h-info" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a href="https://www.twitter.com" className="h-info" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com" className="h-info" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-instagram"></i>
          </a>
        </div>
      </div>

      <div className="row align-items-center d-none d-lg-flex">
        <div className="col-6">
          {/* Logo section */}
          {/* <img src={logo} style={{ padding: "0px", width: "100%" }} alt="Logo" className="logo-lg" /> */}
        </div>
        <div className="col-6 text-end">
          <div className="d-flex align-items-center justify-content-end">
            <NavLink to="/" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
              Home
            </NavLink>
            {/* <NavLink to="/contact" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
              Contact
            </NavLink>
            <NavLink to="/jobs" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
              Jobs
            </NavLink> */}
            <div style={{ display: 'flex', gap: '10px' }}> {/* Adjust the gap value for spacing */}
  <div>
    <Button
      type="button"
      variant="light"
      onClick={() => navigate("/create-account")}
    >
      Register
    </Button>
  </div>
  <div>
    <Button
      type="button"
      variant="light"
      onClick={() => navigate("/login")}
    >
      Login
    </Button>
  </div>
</div>
          </div>
        </div>
      </div>

      {/* Mobile navigation menu */}
      <div className="phone-menu d-block d-lg-none">
        <div className="d-flex justify-content-between px-2 py-3">
          {/* <img src={logo} style={{ height: "30px" }} alt="Logo" /> */}
          {!showMenu && (
            <Button onClick={() => setMenu(!showMenu)}>
              <i className="fa-solid fa-bars fs-4"></i>
            </Button>
          )}
          {showMenu && (
            <Button onClick={() => setMenu(!showMenu)}>
              <i className="fa-solid fa-xmark fs-4"></i>
            </Button>
          )}
        </div>
        <div className="menu-phone" style={{ height: showMenu ? "90vh" : "0" }}>
          <ul>
            <li>
              <NavLink
                to="/"
                exact
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                onClick={closeMenu}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                onClick={closeMenu}
              >
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/jobs"
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                onClick={closeMenu}
              >
                Jobs
              </NavLink>
            </li>
            <li>
              <Button
                type="button"
                onClick={() => {
                  navigate("/login");
                  closeMenu();
                }}
              >
                Login
              </Button>

            </li>
            <li>
              <Button
                type="button"
                onClick={() => {
                  navigate("/create-account");
                  closeMenu();
                }}
              >
                Register
              </Button>
              
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default SiteNav;
