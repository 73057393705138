// import React, { useEffect, useState } from "react";
import AuthRouter from "./AuthStack";
import HomeRouter from "./HomeStack";
import React from 'react';
import { useSelector } from "react-redux";
const AppRouter = () => {
  const isLoggedin = useSelector((state) => state.authReducer.isLoggedin);
  return isLoggedin ? <HomeRouter /> : <AuthRouter />;
};

export default AppRouter;
