import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { db} from "../firebase-config";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import firebase from "firebase/compat/app"; // Import Firebase app
import "./Login.css";
import {
  CompanyService,
  FirebaseAuthService,
  ProfileService,
} from "../services";
import Swal from "sweetalert2";
import { Checkbox } from "@mui/material";
// import firebase from "firebase/compat/app";



const SignUpPage = () => {
  const emailInput = useRef(null);
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    emailId: "",
    name: "",
    password: "",
    rePassword: "",
    isCompany: false,
  });

  const [error, setError] = useState({
    email: "",
    password: "",
    name: "",
    credentials: "",
  });

  const isValid = () => {
    let flag = true;
    let errorData = {};
    if (credentials.emailId === "") {
      flag = false;
      errorData.email = "Email cannot be blank";
    }
    if (credentials.name === "") {
      flag = false;
      errorData.name = "Name cannot be blank";
    }
    if (credentials.password === "") {
      flag = false;
      errorData.password = "Password cannot be blank";
    }
    if (credentials.password !== credentials.rePassword) {
      flag = false;
      errorData.password = "Passwords do not match";
    }
    setError(errorData);
    return flag;
  };

  const onSignup = async () => {
    if (isValid()) {
      try {
        const res = await FirebaseAuthService.handleEmailSignup(
          credentials.emailId,
          credentials.password
        );
        FirebaseAuthService.verifyEmail(res.user);
  
        if (res.user) {
          Swal.fire("Verification mail has been sent to your mail id");
        }
        // Force `isCompany` to true when calling createProfile
        createProfile(
          res,
          credentials.name,
          credentials.emailId,
          "",
          true, // Ensure this is true to create as a company
          'email'
        );
        console.log(res.user.uid, 'ressy user')
        await handleUserRegistration(res.user.uid);
      } catch (error) {
        Swal.fire("Failed to create account. ", error.message);
      }
    }
  };
  
  // const uidVal = firebase.auth().currentUser.uid;
  const createProfile = (res, name, email, avatar, isCompany, method) => {
    if (res.user) {
      if (isCompany) {
        CompanyService.createCompany(res.user.uid, name, email, avatar);
        

      } else {
        ProfileService.createProfile(res.user.uid, name, email, avatar);
        


      }
      // Check if the method is 'email' or social login and show appropriate alert
    if (method === 'email') {
      Swal.fire("Verification mail has been sent to your email ID.");
    } else {
      Swal.fire("Verification mail has been sent to your email ID. Then please login with your email.");
    }
      console.log(res.user.uid, "res uid")
      // console.log(uidVal, "uid val")
      navigate("/login");
      
    } else {
      Swal.fire("Failed to signup", "Something went wrong");
    }
  };

  // Affiliate Registration Handling
 // Affiliate Registration Handling
 
 const handleUserRegistration = async (userId) => {
  try {
      const affiliateClickId = localStorage.getItem('affiliateClickId');
      const affiliateId = localStorage.getItem('affiliateId');
      const jobAffiliateLinkId = localStorage.getItem('jobAffiliateLinkId'); 
      const companyId = localStorage.getItem('companyId');

      if (affiliateClickId && affiliateId && jobAffiliateLinkId && companyId) {
          console.log("Registering user:", userId);

          // Reference to the affiliate click document
          const clickRef = db.collection('companies')
              .doc(companyId)
              .collection('jobAffiliateLinks')
              .doc(jobAffiliateLinkId)
              .collection('affiliateClicks')
              .doc(affiliateClickId);

          // Update the clickedBy field
          await clickRef.update({
              clickedBy: userId,
          });

          console.log("Updated clickedBy field with user ID:", userId);

          // Reference to the jobAffiliateLink document
          const jobAffiliateLinkRef = db.collection('companies')
              .doc(companyId)
              .collection('jobAffiliateLinks')
              .doc(jobAffiliateLinkId);

          const affiliateSnapshot = await jobAffiliateLinkRef.get();

          if (affiliateSnapshot.exists) {
              await affiliateSnapshot.ref.update({
                  totalClicks: firebase.firestore.FieldValue.increment(1),
                  totalAuthClicks: firebase.firestore.FieldValue.increment(1),
              });
              console.log("Total clicks and authenticated clicks incremented for jobAffiliateLink ID:", jobAffiliateLinkId);
          } else {
              console.error("No affiliate link found for Job Affiliate Link ID:", jobAffiliateLinkId);
          }
      } else {
          console.error("Missing affiliate click data in localStorage");
      }
  } catch (error) {
      console.error("Error during user registration:", error.message);
  }
};




  
  
  
  
  
  

  // const onGoogle = async () => {
  //   try {
      
  //     const res = await FirebaseAuthService.handleGoogleLogin();
      

  //     if (res.user) {
  //       const userProfile = await ProfileService.getProfileDetails(
  //         res.user.uid
  //       );
  //       const companyProfile = await CompanyService.getCompanyDetails(
  //         res.user.uid
  //       );
  //       if (userProfile.exists || companyProfile.exists ) {
          
  //         Swal.fire("Profile Already exists - pls login");
  //         navigate("/login");
  //       return;}
  //       else {
  //     createProfile(
  //       res,
  //       res.user.displayName,
  //       res.user.email,
  //       // res.user.phoneNumber,
  //       res.user.photoURL,
  //       credentials.isCompany,
  //       'google'
  //     );
      
  //      // Handle affiliate click update after registration
  //      await handleUserRegistration(res.user.uid);
      
  //   } }}catch (error) {
  //     Swal.fire("Failed to create account. ", error.message);
  //   }
  // };

  // const onFacebook = async () => {
  //   try {
  //     const res = await FirebaseAuthService.handleFacebookLogin();
      
  //     if (!res.user) return;  // Exit if there is no user
  
  //     // Fetch user and company profiles
  //     const [userProfile, companyProfile] = await Promise.all([
  //       ProfileService.getProfileDetails(res.user.uid),
  //       CompanyService.getCompanyDetails(res.user.uid)
  //     ]);
  
  //     // If either profile exists, log and return
  //     if (userProfile.exists || companyProfile.exists) {
  //       console.log("User or company profile already exists.");
  //       return;  // Exit early since profile already exists
  //     }
  
  //     // If no profile exists, proceed to create the profile
  //     await createProfile(
  //       res,
  //       res.user.displayName,
  //       res.user.email,
  //       // res.user.phoneNumber,
  //       res.user.photoURL,
  //       credentials.isCompany,
  //       'facebook'
  //     );
  //      // Handle affiliate click update after registration
  //      await handleUserRegistration(res.user.uid);
      
  //     console.log("Profile created successfully.");
      
  //   } catch (error) {
  //     console.error("Failed to create account:", error.message);
  //   }
  // };
  
// Scroll up function
// const scrollToTop = () => {
//   if (topOfPageRef && topOfPageRef.current) {
//     topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
//   }
// };
const topOfPageRef = useRef(null); // Reference to the location you want to scroll to
useEffect(() => {
  if (topOfPageRef.current) {
    topOfPageRef.current.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to the ref location
  }
}, []); // Empty dependency array ensures this runs on page load


  useEffect(() => {
    setTimeout(() => {
      if (emailInput && emailInput.current) emailInput.current.focus();
    }, 1000);
  }, []);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {" "}
      <section className="hero-section">
      <div
  className="hero-wrap"
  style={{
    backgroundPositionY: `${0.7 * scrollY}px`
  }}
>
  <div className="coin-front"></div>
  <div className="coin-back"></div>
          <div className="overlay"></div>
          <div className="hero-text">
            <div  className="text-center col-lg-8">
              {/* <h1>Create your new account </h1>
              <h3 className="py-3">Signup </h3> */}
            </div>
          </div>
        </div>
        
      </section >
      <div ref={topOfPageRef}  className="full-page d-flex align-items-center justify-content-center">
        <div   style = {{marginTop:"100px"}}className="login-container">
          <h3 className="my-2">Khonta Partners</h3>
          

          {/* <p className="my-2">
  <span role="img" aria-label="check">✅</span> REGISTER as a COMPANY to POST JOBS
</p>
<p className="my-2">
  <span role="img" aria-label="check">✅</span> REGISTER with FB to AutoPOST to FB
</p>

<p className="my-2">
  <span role="img" aria-label="heart">❤️</span> Site by an SLP 4 SLPs. <span role="img" aria-label="love">❤️</span> 
</p> */}

          <div className="d-flex  my-3">
          <div className="company-checkboxes">

  <div className="company-checkbox">
  <Checkbox
    checked={credentials.isCompany} 
    onChange={() => {
      setCredentials({
        ...credentials,
        isCompany: true, 
      });
    }}
  />
  <div style={{ display: "inline-block" }}> 
    <span>Click this box to Acknowledge Terms</span>
   
  </div>
</div>



{/* Register as a profile (non-company) */}
{/* <div className="company-checkbox">
  <Checkbox
    checked={!credentials.isCompany} // Only true when it's not a company
    onChange={() => {
      setCredentials({
        ...credentials,
        isCompany: false, // Set to false when profile is selected
      });
    }}
  />
  <span>Apply for Jobs</span>
</div> */}

</div>

            </div>
          <div className="login-form">
            <FloatingLabel
              controlId="name"
              label="Full Name"
              className="login-input mb-2"
            >
              <Form.Control
                ref={emailInput}
                placeholder="Full Name"
                value={credentials.name}
                onChange={(e) => {
                  setCredentials({ ...credentials, name: e.target.value });
                }}
              />
              {error.name && <p className="error-input">{error.name}</p>}
            </FloatingLabel>
            <FloatingLabel
              controlId="email"
              label="Email address"
              className="login-input mb-2"
            >
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={credentials.emailId}
                onChange={(e) => {
                  setCredentials({ ...credentials, emailId: e.target.value });
                }}
              />
              {error.email && <p className="error-input">{error.email}</p>}
            </FloatingLabel>
            <FloatingLabel
              controlId="password"
              label="Create Password"
              className="login-input mb-2"
            >
              <Form.Control
                type="password"
                placeholder="Password"
                value={credentials.password}
                onChange={(e) => {
                setCredentials({ ...credentials, password: e.target.value });
                }}
              />
              {error.password && (
                <p className="error-input">{error.password}</p>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="repassword"
              label="Re Enter Password"
              className="login-input mb-2"
            >
              <Form.Control
                type="password"
                placeholder="Password"
                value={credentials.rePassword}
                onChange={(e) => {
                  setCredentials({
                    ...credentials,
                    rePassword: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") onSignup();
                }}
              />
              {error.password && (
                <p className="error-input">{error.password}</p>
              )}
            </FloatingLabel>
            <div className="d-flex justify-content-center my-3">
              {/* <div className="company-checkbox">
                <Checkbox
                  checked={credentials.isCompany}
                  onChange={() => {
                    setCredentials({
                      ...credentials,
                      isCompany: !credentials.isCompany,
                    });
                  }}
                />
                <span>Click this box to confirm Registration</span>
              </div> */}
            </div>
            <div className="d-grid my-3">
              {error.credentials && (
                <p className="error-input">{error.credentials}</p>
              )}
              <Button onClick={onSignup}>Create Account</Button>
              <p className="text-center m-2">Or</p>
              {/* <Button
                className="btn-google my-2"
                variant="outline-primary"
                onClick={onGoogle}
              >
                Signup with Google
              </Button> */}
              {/* <Button className="btn-fb my-2" onClick={onFacebook}>
                Signup with Facebook
              </Button> */}

              <p className="my-3 text-center">
                Already have an account?{" "}
                <span
                  className="text-primary btn-link"
                  onClick={() => navigate("/login")}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 py-5"></div>
    </>
  );
};
export default SignUpPage;