import React, { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from "../../firebase-config"; // Import your Firebase config
import firebase from "firebase/compat/app"; // Import Firebase app
import "firebase/compat/firestore"; // Import Firestore

const RouteHandler = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Function to increment total clicks
    const incrementTotalClicks = async (companyId, jobAffiliateLinkId) => {
        try {
            const jobAffiliateLinkRef = db
                .collection('companies')
                .doc(companyId)
                .collection('jobAffiliateLinks')
                .doc(jobAffiliateLinkId); // Using JobAffiliateLink ID

            // Increment the totalClicks field directly in the jobAffiliateLink document
            await jobAffiliateLinkRef.set({
                totalClicks: firebase.firestore.FieldValue.increment(1)
            }, { merge: true }); // Ensure this only updates the totalClicks without altering other fields

            console.log("Total clicks incremented successfully for ID:", jobAffiliateLinkId);
        } catch (error) {
            console.error("Error incrementing total clicks:", error.message || error);
        }
    };

    // Function to log the affiliate click
   
    const logAffiliateClick = useCallback(async (companyId, affiliateId, jobAffiliateLinkId) => {
      try {
          console.log("Logging click for Company ID:", companyId, "Affiliate ID:", affiliateId, "Job Affiliate Link ID:", jobAffiliateLinkId);
    
          const affiliateClicksRef = db
              .collection('companies')
              .doc(companyId)
              .collection('jobAffiliateLinks')
              .doc(jobAffiliateLinkId) 
              .collection('affiliateClicks');
    
          // Log the click in the affiliateClicks subcollection
          const clickDoc = await affiliateClicksRef.add({
              affiliateId,
              clickedBy: null, // Placeholder for now
              clickedAt: new Date(),
          });
    
          console.log("Affiliate click logged successfully.");
    
          // Set local storage items for use on registration
          localStorage.setItem('affiliateClickId', clickDoc.id); // Store the document ID
          localStorage.setItem('affiliateId', affiliateId);
          localStorage.setItem('jobAffiliateLinkId', jobAffiliateLinkId);
          localStorage.setItem('companyId', companyId);
    
          // Now increment totalClicks
          await incrementTotalClicks(companyId, jobAffiliateLinkId); 
      } catch (error) {
          console.error("Error logging affiliate click:", error.message);
      }
    }, []);
    
  
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const affiliateId = params.get('ref');
        const jobAffiliateLinkId = params.get('jobAffiliateLinkId'); // Get the jobAffiliateLinkId from URL params
        const companyId = params.get('company'); // Get companyId from the URL

        const logClickAndRedirect = async () => {
            if (affiliateId && jobAffiliateLinkId && companyId) {
                try {
                    console.log("Attempting to fetch Job details for Job Affiliate Link ID:", jobAffiliateLinkId);

                    // Fetch the specific jobAffiliateLink document to retrieve jobId
                    const jobAffiliateLinkSnapshot = await db
                        .collection('companies')
                        .doc(companyId)
                        .collection('jobAffiliateLinks')
                        .doc(jobAffiliateLinkId)
                        .get();

                    if (!jobAffiliateLinkSnapshot.exists) {
                        console.error("No Job Affiliate Link found for the provided Job Affiliate Link ID.");
                        return;
                    }

                    const jobAffiliateLinkData = jobAffiliateLinkSnapshot.data();
                    const jobId = jobAffiliateLinkData.jobId; // Assume jobId is stored in the document
                    console.log("Retrieved Job ID from Job Affiliate Link:", jobId);

                    // Log the click
                    await logAffiliateClick(companyId, affiliateId, jobAffiliateLinkId);
                    console.log(`Logged click for affiliate ${affiliateId} on job affiliate link ${jobAffiliateLinkId}.`);

                    // Redirect to the job details page
                    navigate(`/manage-trades/details/${jobId}`); // Navigate using the Job ID
                } catch (error) {
                    console.error("Failed to log affiliate click:", error.message || error);
                }
            } else {
                console.error("Missing parameters for click logging:", { affiliateId, jobAffiliateLinkId, companyId });
            }
        };

        logClickAndRedirect();
    }, [location.search, navigate, logAffiliateClick]);

    return (
        <div>
            <p>Redirecting...</p>
        </div>
    );
};

export default RouteHandler;
